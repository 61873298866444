var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "case-log-container" }, [
    _c(
      "div",
      { staticClass: "case-log" },
      [
        _c(
          "fb-header",
          {
            attrs: { slot: "header", title: "律师工作周报", fixed: "" },
            slot: "header",
          },
          [
            _c(
              "fb-button",
              {
                attrs: {
                  slot: "left",
                  icon: "back",
                  size: "small",
                  type: "primary",
                },
                on: {
                  click: function ($event) {
                    return _vm.$router.back()
                  },
                },
                slot: "left",
              },
              [_vm._v(" 返回 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "case-log-cell log-header" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v(_vm._s(_vm.header.lawfirmName)),
          ]),
          _c(
            "div",
            { staticClass: "middle" },
            [
              _c("big", [_vm._v(_vm._s(_vm.header.caseName))]),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.header.lawyerName) + "工作周报")]),
            ],
            1
          ),
          _c("div", { staticClass: "bottom" }, [
            _vm._v(" " + _vm._s(_vm.calcDate(_vm.header.caseCreateTime)) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "case-log-cell schedule" },
          [
            _vm._m(0),
            _vm._l(_vm.schedule, function (item, index) {
              return _c("div", { key: index, staticClass: "cell" }, [
                _c("div", { staticClass: "left" }, [
                  _c("span", { staticClass: "dot" }),
                  _c("span", [_vm._v(_vm._s(_vm.calcDate(item.completeDate)))]),
                  item.dateType === "datetime"
                    ? _c("span", { staticClass: "time" }, [
                        _vm._v(
                          " " + _vm._s(_vm.calcTime(item.completeDate)) + " "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "middle" },
                  [
                    _c(
                      "big",
                      {
                        class:
                          item.currentStateEnum === "COMPLETED"
                            ? "completed"
                            : item.currentStateEnum === "PROCESSING"
                            ? "processing"
                            : "comingsoon",
                      },
                      [_vm._v(" " + _vm._s(item.nodeName) + " ")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "right" }),
              ])
            }),
            _vm._m(1),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "case-log-cell document" },
          [
            _vm._m(2),
            _vm._l(_vm.document, function (item, index) {
              return _c("div", { key: index, staticClass: "cell" }, [
                _c("div", { staticClass: "left" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(" " + _vm._s(_vm.calcDate(item.createTime)) + " "),
                ]),
              ])
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "case-log-cell evidence" },
          [
            _vm._m(3),
            _vm._l(_vm.evidence, function (item, index) {
              return _c("div", { key: index, staticClass: "cell" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("span", {
                      class: ["iconfont", item.icon],
                      style: { color: _vm.getcolor(item.icon) },
                    }),
                  ]),
                  _c("span", [
                    _c("span", [_vm._v(_vm._s(item.fileName))]),
                    _c("br"),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.calcDate(item.createTime))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "right" }),
              ])
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "case-log-cell cooperation" },
          [
            _vm._m(4),
            _vm._l(_vm.cooperation, function (item, index) {
              return _c("div", { key: index, staticClass: "cell" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", { attrs: { src: item.avatarUrl } }),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(item.lawyerName))]),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(_vm.calcDate(item.createTime)))]),
                  ]),
                ]),
                _c("div", { staticClass: "right" }),
              ])
            }),
          ],
          2
        ),
        _c("div", { staticClass: "case-log-cell phone" }, [
          _c("div", { staticClass: "cell added" }, [
            _c("div", { staticClass: "left" }, [_vm._v("发送手机号为")]),
            _c(
              "div",
              { staticClass: "right addedphone" },
              _vm._l(_vm.addedContacts, function (item, index) {
                return _c("div", { key: index, staticClass: "contactsbox" }, [
                  _c("div", { staticClass: "contacts" }, [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c("span", [_vm._v(_vm._s(item.phone))]),
                  ]),
                  _c(
                    "label",
                    { staticClass: "box", attrs: { for: item.litigantId } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.choosedContacts,
                            expression: "choosedContacts",
                          },
                        ],
                        attrs: { type: "checkbox", id: item.litigantId },
                        domProps: {
                          value: { item, index },
                          checked: Array.isArray(_vm.choosedContacts)
                            ? _vm._i(_vm.choosedContacts, { item, index }) > -1
                            : _vm.choosedContacts,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.choosedContacts,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = { item, index },
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.choosedContacts = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.choosedContacts = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.choosedContacts = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" --> "),
                      _c("span", { staticClass: "iconfont icon-selected" }),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "span",
            { staticClass: "addmorephone", on: { click: _vm.addMoreContact } },
            [
              _c("span", { staticClass: "iconfont icon-tianjia" }),
              _vm._v(" 添加手机号码 "),
            ]
          ),
          _c(
            "div",
            { staticClass: "cell addphone" },
            _vm._l(_vm.moreContacts, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "contactsadd" },
                [
                  _c("van-field", {
                    staticClass: "username",
                    attrs: {
                      type: "text",
                      placeholder: "姓名",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: item.name,
                      callback: function ($$v) {
                        _vm.$set(item, "name", $$v)
                      },
                      expression: "item.name",
                    },
                  }),
                  _c("van-field", {
                    staticClass: "userphone",
                    attrs: {
                      type: "number",
                      placeholder: "手机号码",
                      maxlength: "11",
                      size: "mini",
                      border: "",
                      clearable: "",
                    },
                    model: {
                      value: item.phone,
                      callback: function ($$v) {
                        _vm.$set(item, "phone", $$v)
                      },
                      expression: "item.phone",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "box", attrs: { for: "choosed" + index } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.choosedContacts,
                            expression: "choosedContacts",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "choosed" + index,
                          checked: "true",
                        },
                        domProps: {
                          value: { item, index },
                          checked: Array.isArray(_vm.choosedContacts)
                            ? _vm._i(_vm.choosedContacts, { item, index }) > -1
                            : _vm.choosedContacts,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.label(item, index)
                          },
                          change: function ($event) {
                            var $$a = _vm.choosedContacts,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = { item, index },
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.choosedContacts = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.choosedContacts = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.choosedContacts = $$c
                            }
                          },
                        },
                      }),
                      _c("span", { staticClass: "iconfont icon-selected" }),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "bottom-box" }, [
          _c("span", { on: { click: _vm.sendCase } }, [_vm._v(" 确定发送 ")]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }),
      _c("div", { staticClass: "middle" }, [
        _c("strong", [_vm._v("整体进展")]),
      ]),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("span", { staticClass: "dot iconfont icon-iconfontwenjian2" }),
        _c("span", [_vm._v("已整理文书")]),
      ]),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("span", { staticClass: "dot iconfont icon-tupian" }),
        _c("span", [_vm._v("已整理证据")]),
      ]),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("span", { staticClass: "dot iconfont icon-mine" }),
        _c("span", [_vm._v("团队协作记录")]),
      ]),
      _c("div", { staticClass: "right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }