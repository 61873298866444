<template>
  <div class="case-log-container">
    <div class="case-log">
      <!-- 移动端头部 添加返回功能 -->
      <fb-header slot="header" title="律师工作周报" fixed>
        <fb-button
          slot="left"
          icon="back"
          @click="$router.back()"
          size="small"
          type="primary"
        >
          返回
        </fb-button>
      </fb-header>
      <!-- 内容 -->
      <div class="case-log-cell log-header">
        <div class="top">{{ header.lawfirmName }}</div>
        <div class="middle">
          <big>{{ header.caseName }}</big>
          <br />
          <span>{{ header.lawyerName }}工作周报</span>
        </div>
        <div class="bottom">
          {{ calcDate(header.caseCreateTime) }}
        </div>
      </div>
      <!-- 整体进度 -->
      <div class="case-log-cell schedule">
        <div class="header">
          <div class="left"></div>
          <div class="middle">
            <strong>整体进展</strong>
          </div>
          <div class="right">
            <!-- 全选 -->
          </div>
        </div>
        <div class="cell" v-for="(item, index) in schedule" :key="index">
          <div class="left">
            <span class="dot"></span>
            <span>{{ calcDate(item.completeDate) }}</span>
            <span class="time" v-if="item.dateType === 'datetime'">
              {{ calcTime(item.completeDate) }}
            </span>
          </div>
          <div class="middle">
            <big
              :class="
                item.currentStateEnum === 'COMPLETED'
                  ? 'completed'
                  : item.currentStateEnum === 'PROCESSING'
                  ? 'processing'
                  : 'comingsoon'
              "
            >
              {{ item.nodeName }}
            </big>
            <!-- <span>未开始</span> -->
          </div>
          <div class="right">
            <!-- <span class="iconfont icon-yuanxingweixuanzhong"></span> -->
          </div>
        </div>
        <div class="footer">
          <div class="left"></div>
        </div>
      </div>
      <!-- 已整理文书 -->
      <div class="case-log-cell document">
        <div class="header">
          <div class="left">
            <span class="dot iconfont icon-iconfontwenjian2"></span>
            <span>已整理文书</span>
          </div>
          <div class="right">
            <!-- 全选 -->
          </div>
        </div>
        <div class="cell" v-for="(item, index) in document" :key="index">
          <div class="left">
            {{ item.name }}
          </div>
          <div class="right">
            {{ calcDate(item.createTime) }}
          </div>
        </div>
      </div>
      <!-- 证据 -->
      <div class="case-log-cell evidence">
        <div class="header">
          <div class="left">
            <span class="dot iconfont icon-tupian"></span>
            <span>已整理证据</span>
          </div>
          <div class="right">
            <!-- 全选 -->
          </div>
        </div>
        <div class="cell" v-for="(item, index) in evidence" :key="index">
          <div class="left">
            <div class="icon-box">
              <span
                :class="['iconfont', item.icon]"
                :style="{ color: getcolor(item.icon) }"
              ></span>
            </div>
            <span>
              <span>{{ item.fileName }}</span
              ><br />
              <span class="time">{{ calcDate(item.createTime) }}</span>
            </span>
          </div>
          <div class="right">
            <!-- <span class="iconfont icon-yuanxingweixuanzhong"></span> -->
          </div>
        </div>
      </div>
      <!-- 收集材料记录 -->
      <!-- <div class="case-log-cell search">
        <div class="header">
          <div class="left">
            <span class="dot iconfont icon-sousuo"></span>
            <span>收集材料记录</span>
          </div>
          <div class="right">
            全选
          </div>
        </div>
        <div class="cell">
          <div class="left">
            <big>案由名称</big><br>
            <span class="time">2018年10月12日</span>
          </div>
          <div class="right">
            <span class="iconfont icon-yuanxingweixuanzhong"></span>
          </div>
        </div>
      </div> -->
      <!-- 协作人 -->
      <div class="case-log-cell cooperation">
        <div class="header">
          <div class="left">
            <span class="dot iconfont icon-mine"></span>
            <span>团队协作记录</span>
          </div>
          <div class="right">
            <!-- 全选 -->
          </div>
        </div>
        <div class="cell" v-for="(item, index) in cooperation" :key="index">
          <div class="left">
            <img :src="item.avatarUrl" />
            <div>
              <span>{{ item.lawyerName }}</span
              ><br />
              <span>{{ calcDate(item.createTime) }}</span>
            </div>
          </div>
          <div class="right">
            <!-- <span class="iconfont icon-yuanxingweixuanzhong"></span> -->
          </div>
        </div>
      </div>
      <div class="case-log-cell phone">
        <div class="cell added">
          <div class="left">发送手机号为</div>
          <div class="right addedphone">
            <div
              class="contactsbox"
              v-for="(item, index) in addedContacts"
              :key="index"
            >
              <div class="contacts">
                <span>{{ item.name }}</span>
                <span>{{ item.phone }}</span>
              </div>
              <label class="box" :for="item.litigantId">
                <input
                  type="checkbox"
                  v-model="choosedContacts"
                  :id="item.litigantId"
                  :value="{ item, index }"
                />
                -->
                <span class="iconfont icon-selected"></span>
              </label>
            </div>
          </div>
        </div>
        <span class="addmorephone" @click="addMoreContact">
          <span class="iconfont icon-tianjia"></span> 添加手机号码
        </span>
        <div class="cell addphone">
          <div
            class="contactsadd"
            v-for="(item, index) in moreContacts"
            :key="index"
          >
            <!-- <input placeholder="姓名"
                   size="mini"
                   v-model="item.name" /> -->

            <!-- <input placeholder="手机号码"
                   type="number"
                   size="mini"
                   v-model="item.phone" /> -->
            <van-field
              v-model="item.name"
              class="username"
              type="text"
              placeholder="姓名"
              size="mini"
              clearable
            />
            <van-field
              v-model="item.phone"
              class="userphone"
              type="number"
              placeholder="手机号码"
              maxlength="11"
              size="mini"
              border
              clearable
            />
            <label class="box" :for="'choosed' + index">
              <input
                type="checkbox"
                :id="'choosed' + index"
                v-model="choosedContacts"
                :value="{ item, index }"
                checked="true"
                @click="label(item, index)"
              />
              <span class="iconfont icon-selected"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <span @click="sendCase">
          确定发送
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
export default {
  name: 'Customer',
  computed: {
    caseId() {
      return this.$route.params.caseId
    }
  },
  data() {
    return {
      // 案件文件夹id
      fileId: null,
      userId: null,
      // 加载状态
      loading: true,
      // 头部信息
      header: {},
      // 整体进展
      schedule: null,
      // 已整理文书
      document: null,
      // 已整理证据
      evidence: null,
      // 收集材料记录
      search: null,
      // 团队协作记录
      cooperation: null,
      // 已经存在的联系人
      addedContacts: [],
      // 添加的联系人
      moreContacts: [],
      // 选中联系人
      choosedContacts: [],
      isphone: 0,
      ischoose: false,
      // 字体图标map
      fileIconClassMap: {
        jpg: 'icon-tupian',
        jpeg: 'icon-tupian',
        png: 'icon-tupian',
        gif: 'icon-tupian',
        bmp: 'icon-tupian',
        pdf: 'icon-PDF',
        doc: 'icon-doc_huaban',
        docx: 'icon-doc_huaban',
        xls: 'icon-exl',
        xlsx: 'icon-exl',
        txt: 'icon-iconfontwenjian2'
      }
    }
  },
  methods: {
    label(item, index) {
      console.log(item)
      console.log(index)
      var check = document.getElementById('choosed' + index).checked
      if (check) {
        if (/^1(0|1|3|4|5|6|7|8|9)\d{9}$/.test(item.phone)) {
          this.ischoose = true
        } else {
          this.$toast('手机号码格式错误')
        }
      }
    },
    calcDate(time) {
      const dateTime = new Date(time)
      return `${dateTime.getFullYear()}年${dateTime.getMonth() +
        1}月${dateTime.getDate()}日`
    },
    calcTime(time) {
      const dateTime = new Date(time)
      const h = dateTime.getHours()
      const m = dateTime.getMinutes()
      return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`
    },
    getcolor(classname) {
      switch (classname) {
        case 'icon-wenjian':
          return '#4b9efb'
        case 'icon-txt1':
          return '#efa73f'
        case 'icon-html1':
          return '#49c1ea'
        case 'icon-word':
          return '#6ca8ff'
        case 'icon-exl':
          return '#8bdd6c'
        case 'icon-ppt':
          return '#f3c143'
        case 'icon-PDF1':
          return '#3eb3f0'
        case 'icon-jpg1':
          return '#ff7739'
        case 'icon-shipin':
          return '#4b9efb'
        case 'icon-link':
          return '#4b9efb'
        case 'icon-website':
          return '#4b9efb'
        case 'icon-jinriricheng':
          return '#4b9efb'
        case 'icon-web':
          return '#4b9efb'
        case 'icon-falv':
          return '#4b9efb'
      }
    },
    // 文件图标class
    fileType(name, isDir) {
      if (isDir) {
        return 'icon-folder'
      }
      var nameArray = name.split('.')
      var houzui = nameArray[nameArray.length - 1]
      if (this.fileIconClassMap[houzui]) {
        return this.fileIconClassMap[houzui]
      } else {
        return 'icon-iconfontwenjian2'
      }
    },
    // 添加更多联系人
    addMoreContact() {
      const a = {}
      a.name = null
      a.phone = null
      this.moreContacts.push(a)
    },
    // 确认发送请求
    sendCase() {
      const params = {}
      params.caseProgressEntity = {}
      params.lawyerName = this.header.lawyerName
        ? this.header.lawyerName
        : this.$store.state.user.userInfo.nickName
      params.phones = []
      var isOk = true
      if (this.choosedContacts.length) {
        this.choosedContacts.forEach((item, index) => {
          if (!/^1[3456789]\d{9}$/.test(item.item.phone)) {
            this.$toast('请填写正确的手机号')
            isOk = false
          } else {
            params.phones.push(item.item.phone)
          }
        })
      } else {
        this.$toast('未填写手机号，请至少填写一个')
        isOk = false
      }
      const header = this.header
      const schedule = this.schedule
      const document = this.document
      const evidence = this.evidence
      const cooperation = this.cooperation
      const content = { header, schedule, document, evidence, cooperation }
      if (isOk) {
        this.$axios
          .post(`${this.$base}/management/user/${this.userId}/collection`, {
            content: JSON.stringify(content),
            level: 1,
            name: '工作周报', // 固定name
            parentId: this.fileId,
            type: 'WEEKREPORT', // ?????确定type
            updateTime: new Date().getTime()
          })
          .then(res => {
            console.log(res, '本地文件夹保存成功')
            if (res.data.code === 200) {
              // this.$message.success('创建成功')
              params.collectionId = res.data.data.id
              this.$axios
                .post(`${this.$base}/lts/pro/sendSms`, params)
                .then(res => {
                  console.log(res.data, '----res---res---')
                  this.$toast('发送成功')
                })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  },
  created() {
    this.userId = this.$store.state.user.userInfo.id
  },
  async mounted() {
    // console.log(this.fileId, '-------fileId-------')
    this.$axios
      .get(`${caseBase}/lts/case/caseProgress/${this.caseId}`)
      .then(res => {
        // console.log(res)
        // 头部信息
        this.header = res.data.data.caseInfo
        // 整体进度
        this.schedule = res.data.data.completeNode.filter(
          element => element.completeDate
        )
        // 协作人
        this.cooperation = res.data.data.caseShares
        // 发送案件信息
        // this.addedContacts = res.data.data.clientLitigant.filter(
        //   element => element.phone
        // )
        // 取消加载状态
        this.loading = false
        // 获取证据数据
      })
      .catch(err => {
        console.log(err)
      })
    this.$axios
      .get(
        `${this.$base}/lts/caseLitigant/queryClientLitigantList?caseId=${
          this.caseId
        }`
      )
      .then(res => {
        console.log(res, 'getInfo--获取信息')
        if (res.data && res.data.code === '200') {
          this.addedContacts = res.data.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    // 获取证据数据
    // 文书数据请求
    this.$axios
      .get(`${caseBase}/new-design/catalog/list?style=1&caseId=${this.caseId}`)
      .then(res => {
        this.document = res.data.data.catalogs
      })
    // 请求案件信息获得文件夹id
    this.$axios
      .get(`${this.$base}/lts/case/getCase?caseId=${this.caseId}`)
      .then(res => {
        if (res.data.code === '200' && res.data.data) {
          console.log('请求案件信息获得文件夹id', res.data.data.collectionId)
          if (res.data.data.collectionId) {
            this.fileId = parseInt(res.data.data.collectionId)
            if (this.userId && this.fileId) {
              this.$axios
                .get(
                  `${caseBase}/management/user/${
                    this.userId
                  }/collection?&parentId=${
                    this.fileId
                  }&level=1&type=&name=&page=0`
                )
                .then(res => {
                  if (res.data.data && res.data.data.content) {
                    if (res.data.data.content.length > 0) {
                      res.data.data.content.forEach(item => {
                        switch (item.type) {
                          case 'FOLDER':
                            item.icon = 'icon-wenjian'
                            break
                          case 'TXT':
                            item.icon = 'icon-txt1'
                            break
                          case 'HTML':
                            item.icon = 'icon-html1'
                            break
                          case 'WORD':
                            item.icon = 'icon-word'
                            break
                          case 'EXCEL':
                            item.icon = 'icon-exl'
                            break
                          case 'PPT':
                            item.icon = 'icon-ppt'
                            break
                          case 'PDF':
                            item.icon = 'icon-PDF1'
                            break
                          case 'IMAGE':
                            item.icon = 'icon-jpg1'
                            break
                          case 'VIDEO':
                            item.icon = 'icon-shipin'
                            break
                          case 'LINK':
                            item.icon = 'icon-link'
                            break
                          case 'WEBSITE':
                            item.icon = 'icon-website'
                            break
                          // 片段对象
                          case 'FRAGMENT':
                            item.icon = 'icon-web'
                            break
                          // 法律片段
                          case 'LAW_FRAGMENT':
                            item.icon = 'icon-falv'
                            break
                          // 判例片段
                          case 'LEGAL_PRECEDENT_FRAGMENT':
                            item.icon = 'icon-panli'
                            break
                          // 文章片段
                          case 'ARTICLE_FRAGMENT':
                            item.icon = 'icon-wenzhang'
                            break
                          case 'TASK':
                            item.icon = 'icon-jinriricheng'
                            break
                          case 'UNKNOWN':
                            item.icon = 'icon-weizhi1'
                            break
                        }
                      })
                      this.evidence = res.data.data.content
                    } else {
                      this.evidence = []
                    }
                  }
                })
                .catch(err => {
                  Promise.reject(err)
                })
            }
          }
        }
      })
  }
}
</script>
<style scoped>
.case-log {
  /* margin: 30px auto 30px auto; */
  margin: 0 auto;
  /* width: 375px; */
  padding: 60px 8px 30px 8px;
  background-color: RGBA(75, 132, 236, 1);
  font-size: 13px;
  text-align: left;
  line-height: 18px;
}
@media screen and (min-width: 960px) {
  .case-log {
    width: 375px;
  }
}
.case-log-cell {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 15px;
}
.case-log-cell.log-footer {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.case-log-cell.log-footer span {
  color: RGBA(86, 154, 249, 1);
}
.case-log-cell.log-footer button {
  width: 60px;
  height: 25px;
  background-color: RGBA(86, 154, 249, 1);
  border: none;
  border-radius: 2px;
  color: white;
  cursor: pointer;
}
.case-log-cell.log-header {
  color: RGBA(102, 102, 102, 1);
}
.case-log-cell.log-header .top {
  text-align: left;
  line-height: 25px;
}
.case-log-cell.log-header .middle {
  line-height: 22px;
  text-align-last: center;
}
.case-log-cell.log-header .middle small {
  color: RGBA(133, 133, 133, 1);
}
.case-log-cell.log-header .bottom {
  color: RGBA(153, 153, 153, 1);
  text-align-last: right;
}
/* 通用头部 */
.case-log-cell .header {
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.case-log-cell .header .left,
.case-log-cell .header .right {
  display: flex;
  align-items: center;
}
.case-log-cell .header .dot {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 10px;
}
/* 整体进度 */
.schedule > div {
  display: flex;
  align-items: center;
}
.schedule .left {
  min-width: 105px;
  width: 105px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  color: #666666;
}
.schedule .left .time {
  color: #999999;
}
/* 点位置 */
.schedule > .cell .left .dot {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #569af9;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  margin-right: -5.5px;
  margin-top: 20px;
}
.schedule > .cell .left .dot::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #569af9;
  margin: 0.5px 0 0 0;
}
/* 竖线 */
.schedule > .cell .left {
  border-right: 1px solid #569af9;
}
.schedule .middle {
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  flex-grow: 1;
}
.schedule .right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
/* 进度结尾和开头 */
.schedule .footer .left {
  height: 25px;
  border-right: 1px solid #569af9;
  position: relative;
}
/* 进度结尾 */
.schedule .footer .left::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #569af9;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: -2px;
}
/* 进度开头 */
.case-log-cell.schedule .header {
  align-items: flex-end;
}
.case-log-cell.schedule .header .left {
  border-right: 1px solid #569af9;
  position: relative;
  height: 40px;
}
.case-log-cell.schedule .header .left::after {
  content: '';
  display: inline-block;
  background-color: #569af9;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -13px;
}
/* 判断是今天之前还是今天之后的状态 */
/* 完成 */
.completed {
  color: #666666;
}
/* 正在进行 */
.processing {
  color: #f8850f;
  font-size: 19px;
}
/* 将要进行 */
.comingsoon {
  color: #4b84ec;
}
/* 一整理文书 */
.document > div {
  padding: 5px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.document > .cell {
  color: #999999;
}
.document .header .dot {
  background-color: #f9ad40;
}
/* 证据 */
.evidence .header .dot {
  background-color: #569af9;
}
.evidence .cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.evidence .cell .left {
  display: flex;
  align-items: center;
}
/* 证据图标大小调整 */
.evidence .cell .left .icon {
  font-size: 25px;
  color: #569af9;
}
/* 证据图标大小调整 */
.icon-doc_huaban,
.icon-exl,
.icon-PDF {
  font-size: 30px !important;
  margin-left: -2px;
}
.evidence .cell .icon-box {
  width: 40px;
  text-align: left;
}
.evidence .cell .time {
  color: #999999;
}
/* 收集材料记录 */
.search .header .dot {
  background-color: #f96d20;
}
.search .cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search .cell .time {
  color: #999999;
}
/* 协作人 */
.cooperation .header .dot {
  background-color: #f9cb69;
}
.cooperation .cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.cooperation .cell .left {
  display: flex;
  align-items: center;
  color: #999999;
}
.cooperation .cell .left img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 7px;
}
.cooperation .username {
  border: 1px solid #ccc;
}
/* 发送手机号 */
.added {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #569af9;
}
.addedphone {
  min-width: 50%;
}
.contactsbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
  margin-bottom: 5px;
}
.contacts > span {
  margin-right: 10px;
}
.addmorephone {
  color: #569af9;
  cursor: pointer;
}
.contactsadd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.van-field__control {
  color: red;
  background: red;
}
/* 样式覆盖 */
.contactsadd > input:nth-of-type(1) {
  width: 30%;
}
.contactsadd > input:nth-of-type(2) {
  width: 50%;
}
/* .contactsadd > input {
  margin-right: 20px;
} */
/* 选择框样式 */
.box {
  position: relative;
}
.box > input {
  position: absolute;
  visibility: hidden;
}
.box > input ~ .iconfont.icon-selected {
  color: gray;
}
.box > input:checked ~ .iconfont.icon-selected {
  color: #478eee;
}
.bottom-box {
  text-align: center;
}
.bottom-box > span {
  display: inline-block;
  background-color: white;
  padding: 5px 10px;
  width: 113px;
  border-radius: 2px;
  cursor: pointer;
}
.success {
  color: #67c23a;
  line-height: 28px;
}
.danger {
  color: #f56c6c;
  line-height: 28px;
}
</style>
